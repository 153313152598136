import {makeStyles, Box} from '@material-ui/core';
import React, {FC} from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        textAlign: 'left',
        width: 'calc(100% - 30%)',
        padding: '0px 10% 20px',
    },
    text: {
        letterSpacing: 0.54,
        color: '#3e3e3e',
        lineHeight: 1.7,
        fontSize: 14,
        '& p > a': {
            color: '#3e3e3e',
        },
    },
    headline: {
        fontWeight: 700,
    },
}));

export const DataPrivacy: FC = () => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Box className={`${classes.headline} ${classes.text}`}>
                Datenschutzerklärung
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                1. Allgemeine Hinweise
            </Box>
            <Box className={classes.text}>
                <p>Vielen Dank für Ihr Interesse an unserer Website.</p>
                <p>
                    Als Websitebetreiber hat für uns der Schutz Ihrer persönlichen Daten
                    höchste Priorität. Wir behandeln Ihre personenbezogenen Daten
                    vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
                    wie in dieser Datenschutzerklärung beschrieben.
                </p>
                <p>
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
                    Daten erhoben. Die vorliegende Datenschutzerklärung erläutert, welche
                    Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
                    und zu welchem Zweck das geschieht.
                </p>
                <p>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
                    der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                    lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
                    möglich.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                2. Hinweis zur verantwortlichen Stelle
            </Box>
            <Box className={classes.text}>
                <p>
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser
                    Website ist:
                </p>
                <p>
                    Neurosensitivity Services GmbH <br/>
                    Dr. Patrice Wyrsch
                    <br/>
                    Hinderi Gasse 30A
                    <br/>
                    3770 Zweisimmen
                    <br/>
                    Schweiz
                    <br/>
                </p>
                <p>
                    E-Mail:{' '}
                    <a href="mailto:kontakt@neurosensitivitaet.com">
                        kontakt@neurosensitivitaet.com
                    </a>
                </p>
                <p>
                    Vertreten durch die Geschäftsführung (Im Folgenden „Wir“ oder
                    „Websitebetreiber“).
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                3. Gemeinsame Verantwortlichkeit gemäß Art. 26 DSGVO
            </Box>
            <Box className={classes.text}>
                <p>
                    Die Neurosensitivity Services GmbH und die jeweiligen lizenzierten Neuro-Coaches (von Dr. Patrice
                    Wyrsch), die unsere Plattform auf ihren Webseiten einbetten, sind gemeinsam für die Verarbeitung
                    Ihrer personenbezogenen Daten verantwortlich. Im Rahmen dieser gemeinsamen Verantwortlichkeit haben
                    wir gemäß Art. 26 DSGVO eine Vereinbarung getroffen, die die jeweiligen Verpflichtungen und
                    Verantwortlichkeiten klar regelt.</p>
                <p>In der Vereinbarung wurde festgelegt:</p>
                <ul>
                    <li>Die Neurosensitivity Services GmbH ist primär verantwortlich für die technische
                        Bereitstellung der
                        Plattform sowie die Einhaltung der Sicherheitsmaßnahmen.
                    </li>
                    <li>Die lizenzierten Neuro-Coaches sind verantwortlich für die lokale Verarbeitung der von ihnen
                        erhobenen Daten, einschließlich der Kommunikation mit Ihnen und der Nutzung für
                        Coaching-Zwecke.
                    </li>
                </ul>
                <p>
                    Betroffene Personen können ihre Rechte sowohl gegenüber der Neurosensitivity Services GmbH als auch
                    gegenüber dem jeweiligen Neuro-Coach geltend machen. Für weitere Informationen über die wesentlichen
                    Inhalte der Vereinbarung wenden Sie sich bitte an uns unter <a
                    href="mailto:kontakt@neurosensitivitaet.com">
                    kontakt@neurosensitivitaet.com
                </a>
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                4. Definitionen
            </Box>
            <Box className={classes.text}>
                <p>
                    Diese Datenschutzerklärung verwendet die Begriffe so, wie sie auch in
                    der DSGVO definiert sind. Die Begriffsbestimmungen (Art. 4 DSGVO)
                    können Sie z.B. hier einsehen:{' '}
                    <a
                        href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32016R0679"
                        target="_blank"
                    >
                        https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32016R0679
                    </a>
                </p>
                <p>
                    Personenbezogene Daten (4 Nr. 1 DSGVO) sind alle Informationen, die
                    sich auf eine identifizierte oder identifizierbare natürliche Person
                    beziehen; als identifizierbar wird eine natürliche Person angesehen,
                    die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
                    Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu
                    einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen
                    identifiziert werden kann, die Ausdruck der physischen,
                    physiologischen, genetischen, psychischen, wirtschaftlichen,
                    kulturellen oder sozialen Identität dieser natürlichen Person sind;
                </p>
                <p>
                    Cookies sind kleine Textdateien, die von einer Website auf Ihrem
                    Endgerät abgelegt bzw. dort ausgelesen werden. Diese enthalten
                    Buchstaben- bzw. Zahlenkombinationen, anhand derer z.B. bei einer
                    erneuten Verbindung mit der Website der Nutzer bzw. seine
                    Konfigurationen wiedererkannt werden können oder in bestimmten Fällen
                    das Nutzungsverhalten von Besuchern personenbezogen oder statistisch
                    analysiert werden kann.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                5. Allgemeines zur Datenverarbeitung
            </Box>
            <Box className={classes.text}>
                <p>
                    Wir verarbeiten personenbezogene Daten nur, soweit dies gesetzlich
                    zulässig ist. Eine Weitergabe personenbezogener Daten findet
                    ausschließlich in den unten beschriebenen Fällen statt. Die
                    personenbezogenen Daten werden durch angemessene technische und
                    organisatorische Maßnahmen (z.B. Pseudonymisierung, Verschlüsselung)
                    geschützt.
                </p>
                <p>
                    Sofern wir nicht gesetzlich zu einer Speicherung oder einer Weitergabe
                    an Dritte (insbesondere Strafverfolgungsbehörden) verpflichtet sind,
                    hängt die Entscheidung, welche personenbezogenen Daten von uns wie
                    lange verarbeitet werden und in welchem Umfang wir sie ggf.
                    offenlegen, davon ab, welche Funktionen der App Du im Einzelfall
                    nutzt.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                6. Speicherdauer
            </Box>
            <Box className={classes.text}>
                <p>
                    Personenbezogene Daten werden gelöscht, sobald der Zweck der
                    Verarbeitung entfällt oder eine vorgeschriebene Speicherfrist abläuft,
                    es sei denn, dass eine weitere Speicherung der personenbezogenen Daten
                    zur Erfüllung einer sonstigen vertraglichen oder allgemeinen
                    gesetzlichen Pflicht erforderlich ist.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                7. Kategorien von Datenempfängern
            </Box>
            <Box className={classes.text}>
                <p>
                    Empfänger von personenbezogenen Daten sind bei uns Beschäftigte, die
                    nach einem abgestuften Berechtigungskonzept Daten zu den unten
                    genannten Zwecken erforderlichenfalls verarbeiten müssen. Daneben
                    können gegebenenfalls sogenannte Auftragsverarbeiter nach Art. 28
                    DSGVO Daten im Zuge einer Dienstleisterfunktion erhalten, wie
                    IT-Service-Dienstleister (Hosting, Pflege und Support der Website).
                    Diese Dienstleister verarbeiten weisungsgebunden personenbezogene
                    Daten innerhalb der Europäischen Union. Wir verpflichten unsere
                    Dienstleister vertraglich zu angemessenen technischen und
                    organisatorischen Maßnahmen zur Gewährleistung des Datenschutzes sowie
                    zur Wahrung des Datengeheimnisses.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                8. Information über die Datenweitergabe
            </Box>
            <Box className={classes.text}>
                <p>
                    Die über die Plattform erhobenen personenbezogenen Daten, insbesondere Ihre E-Mail-Adresse,
                    Telefonnummer und Testergebnisse des Sensitivitätstypen-Tests, sind sowohl von der Neurosensitivity
                    Services GmbH als auch von den lizenzierten Neuro-Coaches einsehbar. Verwendet werden die Daten
                    hingegen ausschliesslich vom jeweiligen lizenzierten Neuro-Coach.
                </p>
                <p>
                    Diese Daten werden für folgende Zwecke verwendet:
                </p>
                <ul>
                    <li>Durchführung und Auswertung des Sensitivitätstypen-Tests</li>
                    <li>Bereitstellung individueller Coaching-Dienstleistungen durch den jeweiligen Neuro-Coach</li>
                    <li>Allfällige Kontaktaufnahme des jeweiligen Neuro-Coach per E-Mail und/oder Telefon für
                        weiterführende Bedarfsanalyse und allfälliger Angebotsunterbreitung einer
                        Coaching-Dienstleistungs.
                    </li>
                    <li>Verwaltung und Verbesserung der Plattform durch die Neurosensitivity Services GmbH</li>
                </ul>
                <p>
                    Die Daten werden ausschließlich an den Neuro-Coach weitergegeben, über dessen Webseite Sie auf die
                    Plattform zugegriffen haben. Es erfolgt keine Weitergabe an andere Coaches oder unbefugte Dritte.
                    Die Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b DSGVO (Vertragserfüllung) und Art.
                    6 Abs. 1 lit. a DSGVO (Einwilligung, soweit erforderlich).
                </p>
            </Box>

            <Box className={`${classes.headline} ${classes.text}`}>
                9. Datenverarbeitung in einem sog. Drittland (Schweiz)
            </Box>
            <Box className={classes.text}>
                <p>
                    Wir haben unseren Sitz in der Schweiz. Für die Schweiz besteht eine
                    Angemessenheitsbeschluss der Europäischen Kommission, weshalb eine
                    Datenübermittlung aus der EU in die Schweiz grundsätzlich erlaubt ist.
                    Die Europäische Kommission kann gemäß Art. 45 Abs. 3 DSGVO sogenannte
                    Angemessenheitsbeschlüsse fassen. Damit stellt sie fest, dass
                    personenbezogene Daten in einem bestimmten Drittland außerhalb der
                    Europäischen Union einen mit dem Europäischen Datenschutzrecht
                    vergleichbaren, adäquaten Schutz genießen. Hat die Europäische
                    Kommission einen entsprechenden Angemessenheitsbeschluss gefasst, so
                    dürfen personenbezogene Daten, sofern die sonstigen Bestimmungen der
                    DSGVO im Übrigen eingehalten werden, an das jeweilige Land übermittelt
                    werden. Datentransfers auf der Grundlage eines
                    Angemessenheitsbeschlusses sind folglich privilegiert: Sie werden
                    solchen innerhalb der EU gleichgestellt.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                10. Automatisierte Entscheidungen im Einzelfall einschließlich Profiling
            </Box>
            <Box className={classes.text}>
                <p>
                    Automatisierte Entscheidungen im Einzelfall, einschließlich Profiling
                    zur Herbeiführung einer solchen Entscheidung, gemäß Art. 22 Abs. 1, 4
                    DSGVO betreiben wir nicht.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                11. Datenschutzrechte
            </Box>
            <Box className={classes.text}>
                <p>
                    Als betroffene Person im Sinne der DSGVO haben Sie verschiedene
                    Rechte, um Ihre Privatsphäre zu schützen. Diese sind das Recht auf
                    Auskunft gemäß Art. 15 DSGVO, das Recht auf Berichtigung gemäß Art. 16
                    DSGVO, das Recht auf Löschung gemäß Art. 17 DSGVO, das Recht auf
                    Einschränkung der Verarbeitung gemäß Art. 18 DSGVO sowie das Recht auf
                    Datenübertragbarkeit gemäß Art. 20 DSGVO. Beim Auskunftsrecht und beim
                    Löschungsrecht gelten die Beschränkungen aus §§ 34, 35 BDSG.
                </p>
                <p>
                    Sie haben außerdem das Recht, sich bei einer
                    Datenschutzaufsichtsbehörde zu beschweren (Art 77 DSGVO); eine Liste
                    finden Sie zum Beispiel hier:{' '}
                    <a
                        href="https://edpb.europa.eu/about-edpb/board/members_en"
                        target="_blank"
                    >
                        https://edpb.europa.eu/about-edpb/board/members_en
                    </a>
                    .
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                Widerspruchsrecht
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                <p>
                    Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
                    Situation ergeben, jederzeit gegen die Verarbeitung der Sie
                    betreffenden personenbezogenen Daten Widerspruch einzulegen, soweit
                    wir diese auf die berechtigten Interessen von uns oder einem Dritten
                    stützen, Art. 6 Abs. 1 Buchst. f DSGVO.
                </p>
            </Box>
            <Box className={classes.text}>
                <p>
                    Der Widerspruch hinsichtlich etwaiger Cookie-basierter Dienste ist
                    grundsätzlich durch die Vornahme einzelner Browser-Einstellungen
                    möglich, in denen Sie die Verarbeitung von Cookies deaktivieren.
                    Ansonsten können Sie uns einen Widerspruch formlos mitteilen unter den
                    vorstehenden Kontaktdaten, so z.B. per E-Mail oder Post, zukommen
                    lassen.
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                12. Rechte der betroffenen Personen
            </Box>
            <Box className={classes.text}>
                <p>
                    Als betroffene Person haben Sie gemäß der Datenschutz-Grundverordnung (DSGVO) folgende Rechte:
                </p>
                <ul>
                    <li>
                        <b>Auskunftsrecht (Art. 15 DSGVO)</b>: Sie können jederzeit Auskunft
                    </li>
                    <li>
                        <b>Recht auf Berichtigung (Art. 16 DSGVO)</b>: Sollten Ihre Daten unrichtig oder unvollständig
                        sein, haben Sie das Recht auf Berichtigung.
                    </li>
                    <li>
                        <b>Recht auf Löschung (Art. 17 DSGVO)</b>: Sie können die Löschung Ihrer personenbezogenen Daten
                        verlangen, sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
                    </li>
                    <li>
                        <b>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</b>: Sie können die Einschränkung
                        der Verarbeitung Ihrer Daten unter bestimmten Voraussetzungen verlangen.
                    </li>
                    <li>
                        <b>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</b>: Sie haben das Recht, die von Ihnen
                        bereitgestellten Daten in einem gängigen, maschinenlesbaren Format zu erhalten oder an einen
                        anderen Verantwortlichen übertragen zu lassen.
                    </li>
                    <li>
                        <b>Widerspruchsrecht (Art. 21 DSGVO)</b>: Sie können der Verarbeitung Ihrer Daten widersprechen,
                        insbesondere wenn diese auf Grundlage eines berechtigten Interesses erfolgt.
                    </li>
                </ul>
                <p>
                    Um Ihre Rechte auszuüben, können Sie sich sowohl an die Neurosensitivity Services GmbH als auch an
                    den lizenzierten Neuro-Coach wenden, über dessen Webseite Sie auf die Plattform zugegriffen haben.
                    Die Kontaktdaten sind wie folgt:
                </p>
                <ul>
                    <li>
                        <b>Neurosensitivity Services GmbH</b>: <a
                        href="mailto:kontakt@neurosensitivitaet.com">
                        kontakt@neurosensitivitaet.com
                    </a>
                    </li>
                    <li>
                        <b>Lizenzierter Neuro-Coach</b>: Die Kontaktdaten finden Sie auf der Webseite des jeweiligen
                        Coaches.
                    </li>
                </ul>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                13. Datenverarbeitungen im Einzelnen
            </Box>
            <Box className={classes.text}>
                <p>a. Informatorische Nutzung der Website</p>
                <p>
                    Beim Aufrufen und der Nutzung unserer Website erheben wir
                    personenbezogenen Daten, die Ihr Browser automatisch unserem Server
                    mitteilt. Diese Daten werden temporär in einem sog. Logfile
                    gespeichert. Wenn Sie unsere Website nutzen, erheben wir die folgenden
                    Daten, die technisch erforderlich sind, um Ihnen unsere Website
                    anzuzeigen und die Stabilität und Sicherheit unserer
                    informationstechnischen Systeme zu gewährleisten:
                </p>
                <ul>
                    <li>IP-Adresse des anfragenden Rechners,</li>
                    <li>Datum und Uhrzeit des Zugriffs,</li>
                    <li>Name und URL etwaiger abgerufener Dateien,</li>
                    <li>
                        Website, von der aus der Zugriff erfolgt (sog. Referrer-URL),
                        genutzte Browser, ggf. das Betriebssystem Ihres Endgeräts sowie der
                        Name Ihres Access-Providers
                    </li>
                </ul>
                <p>
                    Die genannte Datenverarbeitung stützt sich auf Art. 6 Abs. 1 lit. f
                    DSGVO als Rechtsgrundlage. Die Verarbeitung der genannten Daten ist
                    für die Bereitstellung der Website technisch und sicherheitsbezogen
                    erforderlich und dient damit der Wahrung unserer berechtigten
                    Interessen, unser Unternehmen und unsere Leistungen nach außen
                    darstellen zu können.
                </p>
                <p>
                    Sobald die genannten Daten zur Performance und Darstellung der Website
                    nicht mehr erforderlich sind, werden sie gelöscht. Die Erfassung der
                    Daten zur Bereitstellung der Website und die Speicherung der Daten in
                    Logfiles ist für den Betrieb der Website erforderlich. Es besteht
                    folglich seitens des Nutzers keine Widerspruchsmöglichkeit. Es steht
                    dem Nutzer aber frei, unsere Website in Zukunft nicht mehr aufzurufen.
                    Eine weitergehende Speicherung kann im Einzelfall erfolgen, wenn wir
                    dazu gesetzlich verpflichtet sind oder werden.
                </p>
                <p>b. Analyse- / Tracking-Dienste</p>
                <p>
                    Wir verzichten zum Schutze Ihrer Privatsphäre auf dieser Website auf
                    den Einsatz von Analyse oder Tracking-Diensten. Es werden keine
                    entsprechenden Cookies gesetzt.
                </p>
                <p>c. Kontaktformular</p>
                <p>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
                    Angaben aus dem Anfrageformular inklusive der von Ihnen dort
                    angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
                    Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
                    nicht ohne Ihre Einwilligung weiter.
                </p>
                <p>
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
                    lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
                    zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
                    erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
                    unserem berechtigten Interesse an der effektiven Bearbeitung der an
                    uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
                    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde
                    (zum Beispiel, wenn Gesundheitsdaten übermittelt werden sollen).
                </p>
                <p>
                    Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
                    uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
                    Speicherung widerrufen oder der Zweck für die Datenspeicherung
                    entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
                    Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
                    – bleiben unberührt.
                </p>
                <p>d. Newsletter</p>
                <p>
                    Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten,
                    benötigen wir von Ihnen eine E- Mail-Adresse sowie Informationen,
                    welche uns die Überprüfung gestatten, dass Sie der Inhaber der
                    angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
                    einverstanden sind. Weitere Daten werden nicht bzw. nur auf
                    freiwilliger Basis erhoben.
                </p>
                <p>
                    Für die Anmeldung zum Newsletter verwenden wir das sog.
                    Double-opt-in-Verfahren. Das heißt, dass wir nach Anmeldung des
                    Nutzers eine E-Mail an die angegebene E-Mail-Adresse senden, in
                    welcher der Nutzer um Bestätigung dazu gebeten wird, dass er Inhaber
                    der angegebenen E-Mail-Adresse ist und den Erhalt der
                    Benachrichtigungen wünscht. Wenn die Anmeldung nicht innerhalb von 24
                    Stunden bestätigt wird, werden die Informationen gesperrt und nach
                    einem Monat automatisch gelöscht.
                </p>
                <p>
                    Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
                    Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6
                    Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der
                    Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
                    Newsletters können Sie jederzeit widerrufen, etwa über den
                    „Austragen”-Link im Newsletter. Die Rechtmäßigkeit der bereits
                    erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                </p>
                <p>
                    Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
                    Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
                    uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
                    Abbestellung des Newsletters oder nach Zweckfortfall aus der
                    Newsletterverteilerliste gelöscht. Wir behalten uns vor,
                    E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen
                    im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
                    DSGVO zu löschen oder zu sperren.
                </p>
                <p>
                    Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
                    E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
                    einer Blacklist gespeichert, um künftige Mailings zu verhindern. Die
                    Daten aus der Blacklist werden nur für diesen Zweck verwendet und
                    nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem
                    Interesse als auch unserem Interesse an der Einhaltung der
                    gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
                    Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in
                    der Blacklist ist zeitlich nicht befristet. Sie können der Speicherung
                    widersprechen, sofern Ihre Interessen unser berechtigtes Interesse
                    überwiegen.
                </p>
                <p>e. Geschlossener Nutzungsbereich / Account</p>
                <p>
                    Der Zugang zu dem geschlossenen Benutzerbereich setzt eine vorherige
                    Registrierung und die damit verbundene Zuweisung von Log-In-Daten für
                    einen individuellen Zugang („Account“) voraus.
                </p>
                <p>
                    Für die Registrierung wird das sog. Double-opt-in-Verfahren genutzt.
                    Das heißt, dass wir nach Anmeldung des Nutzers eine E-Mail an die
                    angegebene E-Mail-Adresse senden, in welcher der Nutzer um Bestätigung
                    dazu gebeten wird, dass er Inhaber der angegebenen E-Mail-Adresse ist
                    und die Einrichtung seines Accounts wünscht. Wenn die Anmeldung nicht
                    innerhalb von 24 Stunden bestätigt wird, werden die Informationen
                    gesperrt und nach einem Monat automatisch gelöscht.
                </p>
                <p>
                    Der Nutzer kann freiwillig Inhaltsdaten, die das Coaching und die
                    persönliche Wahrnehmung betreffen, in seinem persönlichem Account
                    eingeben.
                </p>
                <p>
                    Die Verarbeitung der vorstehenden Daten erfolgt zur Durchführung des
                    Vertrags über die Nutzung des Portals (Nutzungsvertrag), Art. 6 Abs. 1
                    lit. b DSGVO. Wir behalten uns vor, auch nach Löschen eines
                    Nutzerkontos die eingeben Inhaltsdaten zu Zwecken einer langfristigen
                    Analyse zu speichern. Diese Speicherung erfolgt dann ohne jeden
                    Personenbezug in aggregierter bzw. anonymisierter Form und unterliegt
                    nicht mehr datenschutzrechtlichen Bestimmungen.
                </p>
                <p>
                    Für die Anmeldung im geschlossenen Nutzungsbereich werden zudem die
                    IP-Adresse und ein Zeitstempel gespeichert. Die Speicherung der
                    IP-Adresse und des Zeitpunkts der Anmeldung und Bestätigung dienen
                    dazu, die Anmeldung nachweisen und ggf. einen möglichen Missbrauch der
                    informationstechnischen Systeme aufklären zu können, und liegen daher
                    in unserem berechtigten Interesse (Art. 6 Abs. 1 lit. f DSGVO).
                </p>
            </Box>
            <Box className={`${classes.headline} ${classes.text}`}>
                14. SSL- bzw. TLS-Verschlüsselung
            </Box>
            <Box className={classes.text}>
                <p>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
                    Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
                    TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                    daran, dass die Adresszeile des Browsers von „http://” auf „https://”
                    wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                </p>
                <p>
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                    Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                    werden.
                </p>
            </Box>
        </Box>
    );
};

export default DataPrivacy;
